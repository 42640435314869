<template>
  <div class="company-info-box">
    <van-nav-bar title="企业信息" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="info-con" v-if="userInfo">
      <div class="base-box">
        <img :src="userInfo.companyVO.avatarUrl" alt />
        <div>
          <h4>{{ userInfo.companyVO.nameCn }}</h4>
          <p>{{ userInfo.companyVO.nameEn }}</p>
          <i>{{ userInfo.companyVO.shortName }}</i>
          <span>
            {{ userInfo.companyVO.companyTypeName }}/{{
            userInfo.companyVO.industryTypeName
            }}/{{ userInfo.companyVO.scaleTypeName }}/{{
            userInfo.companyVO.financingTypeName
            }}
          </span>
        </div>
      </div>
      <div class="info-box">
        <h4>公司介绍</h4>
        <div v-html="userInfo.companyVO.description"></div>
      </div>
      <div class="info-box">
        <h4>公司福利</h4>
        <div v-html="userInfo.companyVO.benefit"></div>
      </div>
      <div class="info-box">
        <h4>面试流程</h4>
        <div v-html="userInfo.companyVO.interviewFlow"></div>
      </div>
      <div class="info-box">
        <h4>公司地址</h4>
        <div>{{ userInfo.companyVO.adressDetail }}</div>
      </div>
      <div class="btn-box">
        <van-button round block type="info" v-if="userInfo.companyVO.creatorId == userInfo.userSelfhelpVO.userId" @click="goEdit">编辑</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'companyInfo',
  data() {
    return {
      userInfo: null,
    };
  },
  async mounted() {
    let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
    if (back.code === 200) {
      localStorage.setItem('userInfo', JSON.stringify(back.data));
      this.userInfo = back.data;
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    goEdit() {
      this.$router.push({ path: '/myHome/companyAddOrEdit', query: { id: this.userInfo.companyVO.id } })
    },
  },
};
</script>

<style lang="scss" scoped>
.company-info-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .info-con {
    flex: 1;
    padding: 0 15px;
    overflow-y: auto;
    .base-box {
      margin-top: 20px;
      display: flex;
      border-bottom: 1px solid #e5e5e5;
      img {
        height: 67px;
        width: 67px;
        margin-right: 17px;
      }
      h4 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 2px 0 12px 0;
      }
      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 10px;
      }
      i {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        font-style: normal;
        display: block;
      }
      span {
        display: inline-block;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        font-style: normal;
        margin: 10px 0 20px 0;
      }
    }
    .info-box {
      h4 {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 30px 0 10px 0;
      }
      div {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
      }
    }
    .btn-box {
      margin-top: 30px;
    }
  }
}
</style>
